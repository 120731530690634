import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/blue-iris-setup/",
  "heroImage": "camera_lens.jpg",
  "title": "Installing Blue Iris on my Home Server",
  "description": "Video Security and Webcam Solution",
  "date": "2022-10-17T00:00:00.000Z",
  "tags": ["home", "code"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Listen, we all know that feeling of paranoia when we leave the house and wonder if everything is okay. Did we remember to lock the doors? Did we turn off the stove? And what about that package that's supposed to arrive today - will it still be there when we get back?`}</p>
    <p>{`Well, if you're anything like me, then you want to do everything in your power to ease those worries and keep your home safe and secure. And that's where Blue Iris comes in.`}</p>
    <p>{`In this blog post, I want to share with you my experience of installing Blue Iris on my home server. Blue Iris is like a personal bodyguard for your home - it lets you monitor and manage your security cameras from a single location, so you can keep an eye on everything and anything that might be going on.`}</p>
    <p>{`Now, I know what you're thinking - "installing security camera software? That sounds complicated!" But fear not, my friend - I'm here to guide you through the process step by step. In this post, I'll show you just how easy it is to set up Blue Iris on your home server, and provide some helpful tips along the way.`}</p>
    <p>{`So, if you're ready to bring some peace of mind to your home security system and maybe even feel a little bit like James Bond in the process, let's get started!`}</p>
    <h2>{`Install Windows 10 onto Proxmox Virtual Machine`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.microsoft.com/en-us/software-download/windows10ISO",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Download the Windows installation file`}</a>{` onto your computer`}</li>
      <li parentName="ol">{`Navigate to: `}<em parentName="li">{`local (proxmox)`}</em>{` > `}<em parentName="li">{`ISO Images`}</em></li>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Upload`}</code>{` and select the `}<strong parentName="li">{`Windows 10 ISO image`}</strong></li>
      <li parentName="ol">{`Let's download the drivers needed for this installation. Go to this `}<a parentName="li" {...{
          "href": "https://github.com/virtio-win/virtio-win-pkg-scripts/blob/master/README.md",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Github repository`}</a>{` to download the VirtIO drivers`}</li>
    </ol>
    <p>{`Or`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Directly `}<a parentName="li" {...{
          "href": "https://fedorapeople.org/groups/virt/virtio-win/direct-downloads/latest-virtio/virtio-win.iso",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`download the Latest virtio-win ISO here`}</a></li>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Upload`}</code>{` and select the `}<strong parentName="li">{`VirtIO ISO image`}</strong></li>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Create VM`}</code></li>
    </ol>
    <h3>{`General`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVQ4y62SS0/DMBCE/fe5wn9BXLiAxBFOHBA0JChpFGia98t5DBlLLpZJIUWsNFo7WX+eXVk42x0e3BhvUYLgI0W0z1AUxZ+0SzKI26cI5zdbbMIYVZEjy78KyrJUOc9ztTZzVVWLElmWYuwlGPyQJAmGYUDf9wdJKdE0jfrPzP9LYq0goGlbBaSbOI7RznsT1nUdpmmCDq6PaXaYKQBvoIM0TVHX9QGmpfemc1tkCLoikMXjOC660ONgLd3yIM+w3m5b0I0GmCATzGAdgfpiE67dqRmawGNiEMRa3Q0dM//o0HZnu1wT31q2QfYoftOqlk+R4EP9z1DPhsM039wpqlqJvJbYFR3eZwnP8+C6LsIwRBAEq+T7PjaOA899xd2jh8t7FxfXLzi7esYnl7ZD/4cTDwwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "General Tab",
          "title": "General Tab",
          "src": "/static/657bba4e1c3236bb66553da2cebd0378/2bef9/vm_win10_1_general_tab.png",
          "srcSet": ["/static/657bba4e1c3236bb66553da2cebd0378/6f3f2/vm_win10_1_general_tab.png 256w", "/static/657bba4e1c3236bb66553da2cebd0378/01e7c/vm_win10_1_general_tab.png 512w", "/static/657bba4e1c3236bb66553da2cebd0378/2bef9/vm_win10_1_general_tab.png 1024w", "/static/657bba4e1c3236bb66553da2cebd0378/8b640/vm_win10_1_general_tab.png 1454w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VM ID:`}</strong>{` 102`}</li>
      <li parentName="ul"><strong parentName="li">{`Name:`}</strong>{` Windows-10`}</li>
    </ul>
    <hr></hr>
    <h3>{`OS`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABMUlEQVQ4y51Sy07DMBDMd/MFSHwEP4E4IK5cuXGlEBVBUiexE6fNqxk8K20foQ/alUb27uyOx06iJHf4yUrMFxZxamGKEt5XqCof1i2qqhLs1vZ5jyzMRibLkJkFHt8MXmcLFJnZDBNlWQp2RZnXdf0H3teISLKpKD0cXYU9ybZtMY4jhmEQsMc5t8mJvu9lXa/XAuZR13VSrIMD5yystVitVlgul2iaRoSFD4cwZ1A4TVMB+1SMWpFuSNBFnucywJXiOkBh9jIoboyRZ1B36jiiG30nvb4OavDqh/aaa43CcmUW9CQltXGaH+I0RHDqZjpwTPgQtyd4ytG5OCp4bZx1eBVOveElrrgIpl/5UuxIbzEGwSRJNn/9f8GZOI7x/TXH/csnbh7ecfs0w93zB34BU5NGDO+TLVAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OS Tab",
          "title": "OS Tab",
          "src": "/static/880aea3938dac9088d1dba9dfe91d1a0/2bef9/vm_win10_2_os_tab.png",
          "srcSet": ["/static/880aea3938dac9088d1dba9dfe91d1a0/6f3f2/vm_win10_2_os_tab.png 256w", "/static/880aea3938dac9088d1dba9dfe91d1a0/01e7c/vm_win10_2_os_tab.png 512w", "/static/880aea3938dac9088d1dba9dfe91d1a0/2bef9/vm_win10_2_os_tab.png 1024w", "/static/880aea3938dac9088d1dba9dfe91d1a0/5faa8/vm_win10_2_os_tab.png 1444w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Storage:`}</strong>{` local`}</li>
      <li parentName="ul"><strong parentName="li">{`ISO image:`}</strong>{` Win10_21H2_English_x64.iso`}</li>
      <li parentName="ul"><strong parentName="li">{`Type:`}</strong>{` Microsoft Windows`}</li>
      <li parentName="ul"><strong parentName="li">{`Version:`}</strong>{` 10/2016/2019`}</li>
    </ul>
    <hr></hr>
    <h3>{`System`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPElEQVQ4y62TWU7DMBCGfW1OAI8cgTvwxiMS4hIsapSEJnES23EWZ/vxuLgyUKGWdqRP42Xmnxk5YVkpsC2VRSLKBZJComk0tNZo9M67ddP8Qgf3RFkrMM5LbPMCrRJ4filw+5jiNSkg68oJK6UcXtT7vu/Rtq3bk9+tWzAKpoDRGFRCIsk4un6AGQaM4whjz4mu6/ZCSjXgnKO3Z9M0uTgPk1K6Kitgq2hUJXcdLcvimOfZMdgC1IUxI/Qwwdhkug9tXVewuq6dABlV8KNQV17UC1M3lBQK0d5DxmgUvyExIQSoCK19MuFHpzMSD4VCGHXlK51qh3IYVf3Z+rEcEj2rw0N2ecFzRv7zUf77IORC9oL0aZz6IF/S32BxHCNNU/cr5Xl+FFmWYRNF+Ehi3D1tcHX/hpuHd1xbPgFl30aXCO5sNwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "System Tab",
          "title": "System Tab",
          "src": "/static/81cefe043f5231805afec058466392dd/2bef9/vm_win10_3_system_tab.png",
          "srcSet": ["/static/81cefe043f5231805afec058466392dd/6f3f2/vm_win10_3_system_tab.png 256w", "/static/81cefe043f5231805afec058466392dd/01e7c/vm_win10_3_system_tab.png 512w", "/static/81cefe043f5231805afec058466392dd/2bef9/vm_win10_3_system_tab.png 1024w", "/static/81cefe043f5231805afec058466392dd/d7ceb/vm_win10_3_system_tab.png 1446w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Qemu Agent:`}</strong>{` Checked`}</li>
    </ul>
    <hr></hr>
    <h3>{`Disks`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABtUlEQVQ4y5VS227UMBD1n/cHkFArfoA/QEIVqhAPlHckJFQVmraUzUI2sZ3bxrmsk4NPgpds2HKxdOKMPXPmzIzFRmWIVO6QYZ1kkGmBL5sMd1GKsihQluU/Q2cFRJxIfIs2kDpFLDWKVOLNtcL5xwRlnu6dt9vtwW6MOQpx+13j/b3CVWSwdv+vrhQugxQmjSGVxjAM6Pv+t93/z8+ttRBt26EfANvzYoAqDOK0RO8uqYZZ6VjXNXa7HZqmGXcSFK4lWZaNNtF1HQQ/05qydW0DU1UusB0DpJSjM0mppHJ3PsaTz5XvCb18BpCIBHmeQ2s9OtL+lXzyX66jhLTbth0zs0yqmJ8tCX3cHwlJQgJP5O15D6nYn3mMQ1kSMoDDWCryPmwJB8W4eQv2CjllKu6Hacosk4RerU/kQTKSMhl7Xfx8/PTlmYA9VEHZxJLIw99xkaR2FbEqa6fBiVBXCGQ94jYxODK8/1gDxIsPG5y+DfHsMsSZ26vGPXS7O2j2Y7AOuemQlC101UGVDcR69YAo/AoVR0iiNcIw/CtWqxWCIMDD/R2evwtw8vITnlx8xtPXN/gBe4s8gOnDys4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Disks Tab",
          "title": "Disks Tab",
          "src": "/static/c58b1f4d6b43af34f1192d7f76d30425/2bef9/vm_win10_4_disks_tab.png",
          "srcSet": ["/static/c58b1f4d6b43af34f1192d7f76d30425/6f3f2/vm_win10_4_disks_tab.png 256w", "/static/c58b1f4d6b43af34f1192d7f76d30425/01e7c/vm_win10_4_disks_tab.png 512w", "/static/c58b1f4d6b43af34f1192d7f76d30425/2bef9/vm_win10_4_disks_tab.png 1024w", "/static/c58b1f4d6b43af34f1192d7f76d30425/bd9eb/vm_win10_4_disks_tab.png 1442w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bus/Device:`}</strong>{` SCSI`}</li>
      <li parentName="ul"><strong parentName="li">{`Storage:`}</strong>{` local-lvm`}</li>
      <li parentName="ul"><strong parentName="li">{`Disk size (GiB):`}</strong>{` 60`}</li>
      <li parentName="ul"><strong parentName="li">{`Cache:`}</strong>{` Write back`}</li>
    </ul>
    <hr></hr>
    <h3>{`CPU`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABrklEQVQ4y2WTa1LrMAyFs28WcNkDv1jE/cMGWAUDnZJJ09h595G2qfAnejoBMnNGsiIdHct2VsbWNrG3InaWV52FprdxHG1IaLrBhmH8Xt+sMAyDYxmLqTYLIVq5rayuGwuxTv7W2qa2vAz29FrYW15ZDJUT9n3vJNj9fu/Y7XZ3jOPOsr7rfDHPsx2PR8c0TclO1u0nO6T14XDwYuLkkS91p9PJzuezW5ApyEcBPy+Xi+M6f1tikAKtIfac6/UHnFBEWHXC/w0IpRB/qeyHQpKlkDWdpQCQyCjYpnzI+KRM+VnTNOlAamvb9s88RPZbNTlLkCfyDLKqqnzokFPY3Q6K5BijN+RfCMEtMcAa4Et1RjHqCFAIMdcCJZCuVisnERG5kKgJ9WrAXO+EKMNChIWMGOo1FkChGgCEaI73GWrLWIi0BTpu00UXNpuNlWXpllxHUuv39zZfVwgpCxER0xWSIm1zafmHEB7ByQ8oKdRTgkgWQuL4kDECWd0IchxtbX16qufpaDanQ8nz3Iqi8I5sga1pO0tfUN56/Wllkdvz69oe/n/Y48uH/Xt5ty+t5TPP7F8l7gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "CPU Tab",
          "title": "CPU Tab",
          "src": "/static/81bb5574cc9e182a6ca02c5df07de32f/2bef9/vm_win10_5_cpu_tab.png",
          "srcSet": ["/static/81bb5574cc9e182a6ca02c5df07de32f/6f3f2/vm_win10_5_cpu_tab.png 256w", "/static/81bb5574cc9e182a6ca02c5df07de32f/01e7c/vm_win10_5_cpu_tab.png 512w", "/static/81bb5574cc9e182a6ca02c5df07de32f/2bef9/vm_win10_5_cpu_tab.png 1024w", "/static/81bb5574cc9e182a6ca02c5df07de32f/d7ceb/vm_win10_5_cpu_tab.png 1446w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Cores:`}</strong>{` 4`}</li>
    </ul>
    <hr></hr>
    <h3>{`Memory`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLUlEQVQ4y6WTS07EMAyG59ycAIkdF+ASSEiIDQtuwJpH1XZIm9BOkr4fP3FKhqETNEET6ZddO/5qt8mGiR22QuLD2DjfgZcSWisjDa0USqkgTEwpvcT+EOVFIbHhQiDLcxRlCc6Nnxm/KEBxWX7i7pnh+jFBzDh4nplCBSklqqo6ktYVNrRBKYm6rjFNE7qus8CmadAbX9UtCm38vrcahsFqHMcjUdwCl5YX64D7YrJ95wV4gQ5C3bkEjUQvaNvWbqJ8yCKGBdK4NCIF3NjOpzXP80ntgdSBezhMunX4vM6t47+Avk2+LnxQLzC0OLjD0OJ/Ac/SqW8Y2hUZKwekc7f+y6HH5Bv9o9kAkyRBmqbmHnNzj7MgMcYQRRG2SYybp3dc3L7g8v4VVw9v+AJH/kdCUkBMuwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Memory Tab",
          "title": "Memory Tab",
          "src": "/static/fda9e7de21eb5875f3167e2610faba18/2bef9/vm_win10_6_memory_tab.png",
          "srcSet": ["/static/fda9e7de21eb5875f3167e2610faba18/6f3f2/vm_win10_6_memory_tab.png 256w", "/static/fda9e7de21eb5875f3167e2610faba18/01e7c/vm_win10_6_memory_tab.png 512w", "/static/fda9e7de21eb5875f3167e2610faba18/2bef9/vm_win10_6_memory_tab.png 1024w", "/static/fda9e7de21eb5875f3167e2610faba18/5faa8/vm_win10_6_memory_tab.png 1444w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Memory (MiB):`}</strong>{` 8192`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimum memory (MiB):`}</strong>{` 1024`}</li>
    </ul>
    <hr></hr>
    <h3>{`Network`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABXUlEQVQ4y5VTW26DMBD0uXuCSv3rBXqJSpWq/vSjN+h3Hygk5WEgxjydMGUgJg5pFLrShM3uMDtrCxEkW2ykwo/cYhVtEWcKWusBeZ5PeaZyyL7n1lzkuYZMFUQcS4RRhDTLIJMUYRghSRJIKZGmKdhP4ghP7yHuX32sAv6PURTFGbQuILQeJ45FjbZtsdvtYIyZnsa0KBsDXfd5XyNnrJuJt9/vh1xwhbIs0XUdlFID2CTpKGhQVyVM24DBd+wG3MaaaJoGgs4oyGCBOQVdWDJ7FLcOXQ4NkTc5HFzU9Zmz09VHMYIDXI7tCYpVVTUV7bQ5uAmH24147u5ZMqeO4PQlwRe4AcMOmR/NcCn8cUmXYMPN58HeieBfBFtfMpAuFzu8irngf+Po/AAraC/l0u1eO9NR6oCuF/R9H+v1uv9m4/47DhchCAJ4noeNv8LD2zduHj9w+/yJu5cv/AJHC0TNvdOeTgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Network Tab",
          "title": "Network Tab",
          "src": "/static/fc888b6c22aecf60bdae5e5ccd41133c/2bef9/vm_win10_7_network_tab.png",
          "srcSet": ["/static/fc888b6c22aecf60bdae5e5ccd41133c/6f3f2/vm_win10_7_network_tab.png 256w", "/static/fc888b6c22aecf60bdae5e5ccd41133c/01e7c/vm_win10_7_network_tab.png 512w", "/static/fc888b6c22aecf60bdae5e5ccd41133c/2bef9/vm_win10_7_network_tab.png 1024w", "/static/fc888b6c22aecf60bdae5e5ccd41133c/5faa8/vm_win10_7_network_tab.png 1444w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Model:`}</strong>{` VirtIO (paravirtualized)`}</li>
    </ul>
    <hr></hr>
    <h3>{`Confirm`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABfElEQVQ4y52T0U6DMBSGeXRfQO+99RW8M/FOH8HE6NRswyFQKLQMRgv75e/WBSNbnBdf0tNTzvnP3xLEQiISBb6yAp9pgTQvoZWC+oWG1grzpMRTKKG0RlVVP8gLhUCIDEmSoigK5LlEmqaQUiLLsv1e7vaYy0WK+2eB68cVpKrQ1DXW6/WBeogDWWrkuoZqzIBF1bTYbDbo+34SYOuYyllrEVBJHCcwQ0CapnHdmJzCnTP2aD7YjSdgjRk2jCtIP8wQ/4eAHgkhXMAO9CGOY+chmzFHGBOu/QSTBWk8D3rJLJgkyXCj+tDkbIW8xbZt0XWdG3mxWGC1WrlGzPHZHPPsh78sWJal+8iPQYXcY4OxOsanlPm8U8gRqc7ub5ne8elwzQZc/1mhf8RjD6MocrAZoeKjysa0+4L0y49INXw23gIq9+qn2PYW6LsDAQ3njfp/duwd16cwA6lqEMoGUbEjCMMQ57JcLjGbvWH+8Y6bhxkubl9wefeKq4FvSMA3L1oM//kAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Confirm Tab",
          "title": "Confirm Tab",
          "src": "/static/03234ad353c4d55a4f4020aecadc52bf/2bef9/vm_win10_8_confirm_tab.png",
          "srcSet": ["/static/03234ad353c4d55a4f4020aecadc52bf/6f3f2/vm_win10_8_confirm_tab.png 256w", "/static/03234ad353c4d55a4f4020aecadc52bf/01e7c/vm_win10_8_confirm_tab.png 512w", "/static/03234ad353c4d55a4f4020aecadc52bf/2bef9/vm_win10_8_confirm_tab.png 1024w", "/static/03234ad353c4d55a4f4020aecadc52bf/d7ceb/vm_win10_8_confirm_tab.png 1446w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Finish`}</strong></li>
    </ul>
    <hr></hr>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Navigate to: `}<strong parentName="li">{`102 (Windows-10)`}</strong>{` > `}<em parentName="li">{`Hardware`}</em></li>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Add`}</code>{` and select `}<strong parentName="li">{`CD/DVD Drive`}</strong></li>
    </ol>
    <h3>{`Add: CD/DVD Drive`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "806px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.1875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABtUlEQVQ4y42STW7bMBCFecZ2nXv0NFl1FwTtCXqEopsm8aJtFpYcy7IoUfzTn1/n0WFqqF5kgA9DiuKbNwOq3aHBr51GWbfYFA1+y/rPi8az0HYGzlpY21+l78/wn7rpUFYaSsulzkVYH9HagM76RG24DjDOXxDe6IUQAnw4Z8tzuaeoHrxPFUPwYMwnYF830F2PSdaDfBgF5jgxL9BuhPMBy7LgdJLzYcA8T1Bd16FtWzjnYIxBZNU4oNIGx1ZaixOMuDd+gBtm+HFJ9PJ9GEcs85xEx2kSwVla1hp1XcOLS86DosSxBck8Z1HmQVzQTXaV15d7FWNEDs6CIhSnAGHknwldsDXmia5enbEY94oijFHsW5knXWbBqqqSOxbhd67zxTX/CTJzjhRlpgBHcTwekzjnzD07Yotnp2fSDMVQmmF2RCE6send2eQmvzOeX8blCDI5VHaUhXLbuUB2cE0kzXT5xyIoVmf/bIWZsH3mtbP3hDocDshvcU1+LmsazlTg47//vsOXHy+4k/ztcQ+13W5RliWKorgKz9YURYn9rsTmeYuPtz9x8/kJH24f8OnrBn8BVcjpLPz5+ScAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "CD/DVD Drive",
          "title": "CD/DVD Drive",
          "src": "/static/883a935efaf0bedb88d72a192593488f/764be/vm_win10_9_drivers_tab.png",
          "srcSet": ["/static/883a935efaf0bedb88d72a192593488f/6f3f2/vm_win10_9_drivers_tab.png 256w", "/static/883a935efaf0bedb88d72a192593488f/01e7c/vm_win10_9_drivers_tab.png 512w", "/static/883a935efaf0bedb88d72a192593488f/764be/vm_win10_9_drivers_tab.png 806w"],
          "sizes": "(max-width: 806px) 100vw, 806px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bus/Device:`}</strong>{` IDE 1`}</li>
      <li parentName="ul"><strong parentName="li">{`Use CD/DVD disc image file (iso):`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Storage:`}</strong>{` local`}</li>
      <li parentName="ul"><strong parentName="li">{`ISO Image:`}</strong>{` virtio-win-0.1.217.iso`}</li>
      <li parentName="ul"><strong parentName="li">{`Add`}</strong></li>
    </ul>
    <hr></hr>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">
        <p parentName="li">{`Right-click `}<strong parentName="p">{`102 (Windows-10)`}</strong>{` and select `}<strong parentName="p">{`Start`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Begin normal `}<strong parentName="p">{`Windows installation`}</strong>{` until you get to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Select `}<strong parentName="li">{`Custom: Install Windows only (advanced)`}</strong></li>
          <li parentName="ul">{`Load driver > Browse > Select `}<strong parentName="li">{`CD Drive (D:) virtio-win-0.1.217`}</strong>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`vioscsi > w10 > amd64 > Next`}</em></li>
            </ul>
          </li>
          <li parentName="ul">{`Load driver > Browse > Select `}<strong parentName="li">{`CD Drive (D:) virtio-win-0.1.217`}</strong>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`NetKVM > w10 > amd64 > Next`}</em></li>
            </ul>
          </li>
          <li parentName="ul">{`Load driver > Browse > Select `}<strong parentName="li">{`CD Drive (D:) virtio-win-0.1.217`}</strong>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Balloon > w10 > amd64 > Next`}</em></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After all drivers above are loaded, click `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Next`}</code></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After logging into Windows:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Right-click `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Start`}</code>{` button > Device Manager > Other devices > Click `}<strong parentName="li">{`PCI Simple Communications Contoller`}</strong></li>
          <li parentName="ul">{`Update Driver > Browse my computer for driver software`}</li>
          <li parentName="ul">{`Browse > Select `}<strong parentName="li">{`CD Drive (D:) virtio-win-0.1.217`}</strong>
            <ul parentName="li">
              <li parentName="ul">{`Click `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`Ok`}</code>{` > `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`Next`}</code></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install Guest Agent`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Browse to `}<strong parentName="li">{`CD Drive (D:) virtio-win-0.1.217`}</strong></li>
          <li parentName="ul">{`guest-agent > `}<strong parentName="li">{`qemu-ga-x86_64`}</strong></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Search for Remote Desktop settings`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Enable Remote Desktop`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      